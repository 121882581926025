import React from 'react';


interface LoadingIconInterface { }

const EpjLoadingAdapteIcon: React.FC<LoadingIconInterface> = ({ }) => {
	const epjLogoPath = "/images/brand/Robozinho.gif";
	// const epjLogoPath = "/images/brand/epj-e.png";

	return (
		<div className="flex items-center justify-center">
			<div className="relative max-w-[500px] max-h-[500px]">
				{/* <img className="w-20 animate-bounce" src={epjLogoPath} alt="" /> */}
				<img className="w-full h-full object-contain" src={epjLogoPath} alt="" />
			</div>
		</div>
	)
}

export default EpjLoadingAdapteIcon;
